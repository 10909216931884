import React, { useState } from "react";

// Modal component
const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div className="bg-white/90 backdrop-blur-xl rounded-3xl p-8 max-w-md w-full relative shadow-lg border border-white/20">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          ✕
        </button>
        {children}
      </div>
    </div>
  );
};

export default function App() {
  const [loading, setLoading] = useState(false);
  const [activeField, setActiveField] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    businessName: "",
    country: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/register_new_user`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            first_name: formData.firstName,
            last_name: formData.lastName,
            email: formData.email,
            business_name: formData.businessName,
            country: formData.country,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        if (
          response.status === 400 &&
          errorData.message ===
            "User already exists in the queue. Please delete the user first"
        ) {
          setModalMessage(
            "You have already registered your interest. If you need to make changes, please contact us."
          );
        } else {
          throw new Error(errorData.message || "Network response was not ok");
        }
      } else {
        setModalMessage(
          "Thank you very much for contacting Backable. We are excited to be coming to life in 2025. You have been added to the waitlist and we will reach out soon to get you going on a new era of high performance in small to medium-sized businesses."
        );
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          businessName: "",
          country: "",
        });
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      setModalMessage(
        "There was a problem with your submission. Please try again later."
      );
    } finally {
      setLoading(false);
      setIsModalOpen(true);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="relative min-h-screen overflow-hidden">
      {/* Enhanced yellow gradient background */}
      <div className="absolute inset-0 bg-yellow-50">
        <div className="absolute inset-0 bg-gradient-to-r from-yellow-100/70 to-white/50 animate-gradient-x" />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-yellow-50/50 to-yellow-100/50 animate-gradient-y" />
        <div className="absolute top-0 -left-1/2 w-[150%] h-[500px] bg-gradient-radial from-white via-yellow-50/70 to-transparent animate-pulse-slow" />
      </div>

      <div className="relative flex flex-col items-center min-h-screen px-4">
        <div className="w-full max-w-lg pt-16">
          {/* Logo and Header */}
          <div className="text-center mb-16 relative">
            <div className="relative">
              <img
                src="/backable-text.png"
                alt="Backable"
                className="mx-auto mb-10 hover:scale-105 transition-transform duration-500"
                style={{ width: '280px', height: '56px' }}
              />
            </div>
            <h1 className="text-3xl font-medium mb-3 text-gray-800">
              Always Ahead, Never Alone
            </h1>
            <p className="text-lg text-gray-600">
              Register Your Interest / Coming Early 2025
            </p>

          </div>

          {/* Form Container with enhanced yellow gradient */}
          <div className="relative bg-white/10 backdrop-blur-xl rounded-3xl p-8 shadow-[0_8px_30px_rgb(0,0,0,0.05)] border border-white/20">
            <div className="absolute inset-0 bg-gradient-to-b from-yellow-50/30 to-yellow-100/30 rounded-3xl animate-shimmer" />

            <form onSubmit={handleSubmit} className="relative space-y-7">
              {[
                {
                  name: "firstName",
                  placeholder: "First Name",
                  type: "text",
                  icon: "👤",
                },
                {
                  name: "lastName",
                  placeholder: "Last Name",
                  type: "text",
                  icon: "👤",
                },
                {
                  name: "email",
                  placeholder: "Email Address",
                  type: "email",
                  icon: "✉️",
                },
                {
                  name: "businessName",
                  placeholder: "Business Name",
                  type: "text",
                  icon: "🏢",
                },
                {
                  name: "country",
                  placeholder: "Country",
                  type: "text",
                  icon: "🌍",
                },
              ].map((field) => (
                <div
                  key={field.name}
                  className={`group relative overflow-hidden border-b-2 transition-all duration-500 ${
                    activeField === field.name
                      ? "border-black"
                      : "border-gray-200"
                  }`}
                >
                  <div className="flex items-center py-3">
                    <span className="mr-4 text-xl group-hover:scale-110 transition-transform duration-300">
                      {field.icon}
                    </span>
                    <input
                      type={field.type}
                      name={field.name}
                      placeholder={field.placeholder}
                      value={formData[field.name]}
                      onChange={handleChange}
                      onFocus={() => setActiveField(field.name)}
                      onBlur={() => setActiveField("")}
                      className="w-full bg-transparent outline-none text-lg placeholder-gray-400 text-gray-800"
                      required
                    />
                  </div>
                  <div className="absolute bottom-0 left-0 h-0.5 w-0 bg-black group-hover:w-full transition-all duration-700" />
                </div>
              ))}

              <button
                type="submit"
                disabled={loading}
                className="relative w-full overflow-hidden bg-black text-white rounded-2xl py-4 mt-10 text-lg font-medium transition-all duration-300 hover:scale-[1.02] active:scale-[0.98] disabled:bg-gray-400 disabled:cursor-not-allowed group"
              >
                <div className="relative z-10">
                  {loading ? (
                    <div className="flex items-center justify-center gap-2">
                      <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                          fill="none"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                      Processing...
                    </div>
                  ) : (
                    "Join the Queue"
                  )}
                </div>
                <div className="absolute inset-0 bg-gradient-to-r from-gray-800 via-gray-700 to-gray-800 opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="text-center">
          <h2 className="text-2xl font-medium mb-4 text-gray-800">
            Thank you for your interest!
          </h2>
          <p className="text-gray-600">{modalMessage}</p>
        </div>
      </Modal>

      <style jsx global>{`
        @keyframes gradient-x {
          0%,
          100% {
            transform: translateX(-50%);
          }
          50% {
            transform: translateX(50%);
          }
        }
        @keyframes gradient-y {
          0%,
          100% {
            transform: translateY(-50%);
          }
          50% {
            transform: translateY(50%);
          }
        }
        @keyframes shimmer {
          0% {
            background-position: -1000px 0;
          }
          100% {
            background-position: 1000px 0;
          }
        }
        .animate-gradient-x {
          animation: gradient-x 15s ease infinite;
        }
        .animate-gradient-y {
          animation: gradient-y 15s ease infinite;
        }
        .animate-pulse-slow {
          animation: pulse 4s cubic-bezier(0.4, 0, 0.6, 1) infinite;
        }
        .animate-shimmer {
          background: linear-gradient(
            to right,
            transparent 0%,
            rgba(255, 255, 255, 0.2) 50%,
            transparent 100%
          );
          background-size: 200% 100%;
          animation: shimmer 3s infinite;
        }
      `}</style>
    </div>
  );
}
